<template>
    <div class="page" v-title data-title="配置网络">
        <div>
            <div class="network_title">
                <p style="margin-left: 5%; color: #0066FF;">联网配置流程说明</p>
            </div>
            <div class="network_desc">
                <p>
                    1. 确保手机已连接wifi网络，设备电源开启且设备wifi网络指示灯闪烁
                </p>
                <p>
                    2. 点击开始配置网络，输入wifi密码后连接配网，并同时按压一下设备wifi网络指示灯
                </p>
                <p>
                    3. 网络配置成功后，设备wifi网络指示灯会保持常亮
                </p>
                <p>
                    4. 如果一次配网未成功，可以反复以上操作，直至配网成功
                </p>
                <p>
                    5. 设备配网成功后，下次使用设备无需再次配网
                </p>
                <p>
                    6. 更改设备wifi网络或修改wifi密码需要重新配网
                </p>
            </div>
            <br>
            <div class="weui-msg__opr-area">
                <p class="weui-btn-area">
                    <a v-on:click="configNetwork" class="weui-btn weui-btn_primary" style="width: 95%; padding: 12px 24px">开始配置网络</a>
                </p>
            </div>
        </div>
        <!--<div class="js_dialog" id="dialog" style="display: none;">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd"></div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary">确认</a>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
    import http from "@/utils/http";
    import wxmp from "@/utils/wxmp";

    export default {
        name: "Awgels_Network",
        data() {
          return {
              deviceNo: this.$route.params.deviceNo
          }
        },
        mounted() {
            wxmp.wxJsapi.init();
        },
        methods: {
            configNetwork() {
                wxmp.wxJsapi.configWiFi(res => {
                    console.log('config wifi res: ' + res);
                    if (res == 'ok') { // 配网成功
                        http.post('/api/device/configNet', {deviceNo: this.deviceNo});
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .network_title {
        height: 45px;
        line-height: 45px;
        font-size: 22px;
        background-color: #FFFFFF;
    }
    .network_desc p { margin: 10px; }
</style>
